import Image1 from "./img/BWS-APP.png";
import Image2 from "./img/BWS-Site.png";
import Image8 from "./img/screencapture-bwsadmindashboard-firebaseapp.png";
import Image10 from "./img/monstarPay.png";
import Image12 from "./img/screencapture-lenco-co-2022-10-05-09_06_29.png";
import Image13 from "./img/screencapture-apps-apple-us-app-lenco-banking-id1571311372-2022-10-05-09_17_33.png";
import Image16 from "./img/cmoney.png";
import Image17 from "./img/blakskill-mobile.png";
import Image18 from "./img/blakskill-site.png";
import Image19 from "./img/blakskill-site-1.png";
import Image20 from "./img/creditville.png";
import Image21 from "./img/lenco-co-login.png";
import Image22 from "./img/lenco-android.png";
import Image23 from "./img/blakskill-android.png";
import Image24 from "./img/creditville-android.png";
import Image25 from "./img/creditville-bank.png";
import Image26 from "./img/creon.png";
import Image27 from "./img/tayrex-google.png";
import Image7 from "./img/nookvest.png";
import Image9 from "./img/next-basket.png";

export const products = [
  {
    id: 1,
    img: `${Image25}`,
    link: "https://creditvillebank.com/",
    type: "web",
  },
  {
    id: 2,
    img: `${Image24}`,
    link: "https://play.google.com/store/apps/details?id=com.creditville&hl=en&gl=US",
    type: "app",
  },
  {
    id: 3,
    img: `${Image20}`,
    link: "https://creditville.ng/",
    type: "web",
  },
  {
    id: 4,
    img: `${Image16}`,
    link: "https://apps.apple.com/us/app/c-money-savings-investment/id1547983942",
    type: "app",
  },
  {
    id: 5,
    img: `${Image12}`,
    link: "https://lenco.co/",
    type: "web",
  },
  {
    id: 6,
    img: `${Image13}`,
    link: "https://apps.apple.com/us/app/lenco-banking/id1571311372",
    type: "app",
  },
  {
    id: 7,
    img: `${Image21}`,
    link: "https://app.lenco.co/",
    type: "web",
  },
  {
    id: 8,
    img: `${Image22}`,
    link: "https://play.google.com/store/apps/details?id=co.lenco&hl=en&gl=US",
    type: "app",
  },
  {
    id: 9,
    img: `${Image26}`,
    link: "https://www.creon.ai/",
    type: "web",
  },
  {
    id: 10,
    img: `${Image27}`,
    link: "https://play.google.com/store/apps/details?id=com.tayrexchange.ap",
    type: "app",
  },
  {
    id: 11,
    img: `${Image18}`,
    link: "https://blakskill.com/",
    type: "web",
  },
  {
    id: 12,
    img: `${Image19}`,
    link: "https://app.blakskill.com/home",
    type: "web",
  },
  {
    id: 13,
    img: `${Image17}`,
    link: "https://apps.apple.com/ng/app/blakskill/id6444443898",
    type: "app",
  },
  {
    id: 14,
    img: `${Image23}`,
    link: "https://play.google.com/store/apps/details?id=com.blakskill.android&hl=en_US",
    type: "app",
  },
  {
    id: 15,
    img: `${Image10}`,
    link: "https://monstarpay.com/",
    type: "web",
  },
  {
    id: 16,
    img: `${Image2}`,
    link: "https://www.sheciety.club/",
    type: "web",
  },
  {
    id: 17,
    img: `${Image1}`,
    link: "https://apps.apple.com/de/app/bws-networking/id1494277536/",
    type: "app",
  },
  {
    id: 18,
    img: `${Image8}`,
    link: "https://bwsadmindashboard.firebaseapp.com/",
    type: "web",
  },
  {
    id: 19,
    img: `${Image7}`,
    link: "https://www.nookvest.com/",
    type: "web",
  },
  {
    id: 20,
    img: `${Image9}`,
    link: "https://next-basket-ruby.vercel.app/",
    type: "web",
  },
  {
    id: 21,
    img: `${Image7}`,
    link: "https://agent.nookvest.com/",
    type: "web",
  },


  // {
  //   id: 14,
  //   img: `${Image14}`,
  //   link: "https://play.google.com/store/apps/details?id=co.lenco",
  // },
  // {
  //   id: 15,
  //   img: `${Image3}`,
  //   link: "https://enairachecker.netlify.app/",
  // },
  // {
  //   id: 13,
  //   img: `${Image5}`,
  //   link: "https://novamblfxtracker.netlify.app/",
  // },
];
