import "./app.scss";
import React, { useContext, useState } from "react";
import { ThemeContext } from "./context";
import Topbar from "./components/topbar/topbar";
import Intro from "./components/intro/intro";
import ProductList from "./components/productList/productList";
import About from "./components/about/about";
import Testimonials from "./components/testimonials/testimonials";
import Contact from "./components/contact/contact";
import Menu from "./components/menu/menu";

const App = () => {
  const theme = useContext(ThemeContext);
  const darkMode = theme.state.darkMode;
  const [menuOpen, setMenuOpen] = useState(false);
  return (
    <div
      style={{
        backgroundColor: darkMode ? "#22272B" : "white",
        color: darkMode && "white",
        minHeight: "100vh"
      }}
    >
      <Topbar menuOpen={menuOpen} setMenuOpen={setMenuOpen} />
      <Menu menuOpen={menuOpen} setMenuOpen={setMenuOpen} />
      <div>
        <Intro />
        <About />
        <ProductList />
        <Testimonials />
        <Contact />
      </div>
    </div>
  );
};

export default App;
