import React, { useContext } from "react";
import "./topbar.scss";
import { Person, Mail } from "@material-ui/icons";
import Toggle from "../toggle/toggle";
import { ThemeContext } from "../../context";

export default function Topbar({ menuOpen, setMenuOpen }) {
  const theme = useContext(ThemeContext);
  const darkMode = theme.state.darkMode;

  return (
    <div
      className={"topbar " + (menuOpen && "active")}
      // style={{ backgroundColor: darkMode ? "#22272B" : "white" }}
    >
      <div className="wrapper">
        <div className="left">
          <a href="#intro" className="logo gradient-text">
            designed by Frezeh®
          </a>
          <div className="itemContainer">
            <Person className="icon" />
            <a href="tel:+2348144923448" className="gradient-text">+234 8144 923 448</a>
          </div>
          <div className="itemContainer">
            <Mail className="icon" />
            <a href="mailto:ezehfrank87@gmail.com" className="gradient-text">ezehfrank87@gmail.com</a>
          </div>
        </div>

        <div className="right">
          <div className="toggle">
            {/* <Toggle /> */}
          </div>
          <div className="hamburger" onClick={() => setMenuOpen(!menuOpen)}>
            <span className="line1"></span>
            <span className="line2"></span>
            <span className="line3"></span>
          </div>
        </div>
      </div>
    </div>
  );
}
