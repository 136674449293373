import "./testimonials.scss";
import Arrow from "../../img/right-arrow.png";
import Youtube from "../../img/youtube.png";
import Twitter from "../../img/twitter.png";
import Linkedin from "../../img/linkedin.png";
import { useMediaQuery } from "@material-ui/core";

export default function Testimonials() {
  const desktop = useMediaQuery("(min-width:600px)");
  const data = [
    {
      id: 1,
      name: "Meshach Ebiwari",
      title: "Product Designer",
      img: "https://images.pexels.com/photos/1680172/pexels-photo-1680172.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500",
      icon: `${Twitter}`,
      desc: "Always able to deliver pixel perfect UI and UX of all kinds.",
    },
    {
      id: 2,
      name: "Martin Nwanu",
      title: "Engineering Manager",
      img: "https://images.pexels.com/photos/3863793/pexels-photo-3863793.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500",
      icon: `${Linkedin}`,
      desc: "You can be guaranteed sole delivey of Reliable, efficient and quality web and mobile apps, without supervision",
      featured: true,
    },
    {
      id: 3,
      name: "Gift",
      title: "Consultant",
      img: "https://images.pexels.com/photos/428321/pexels-photo-428321.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500",
      icon: `${Youtube}`,
      desc: "Exceeded our expectations by helping us deliver a remarkable product in record time",
    },
  ];
  return (
    <div className="testimonials" id="testimonials" style={{ paddingTop: 70 }}>
      <h1>Testimonials</h1>
      <div className="container">
        {data.map((d) => (
          <div className={d.featured ? "card featured" : "card"} key={d.id}>
            <div className="top">
              <img src={Arrow} className="left" alt="" />
              
              {/* <img
                className="user"
                src={d.img}
                alt=""
              /> */}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="49"
                height="48"
                viewBox="0 0 49 48"
                fill="none"
                style={{marginLeft: 20, marginRight: 20}}
              >
                <circle cx="24.5" cy="24" r="24" fill="#F5F5F5" />
              </svg>
              <img className="right" src={d.icon} alt="" />
            </div>
            <div className="center">{d.desc}</div>
            <div className="bottom">
              <h3>{d.name}</h3>
              <h4>{d.title}</h4>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
