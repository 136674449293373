import "./productList.scss";
import Product from "../product/product";
import { products } from "../../data";
import { useMediaQuery } from "@material-ui/core";
import { useState, useRef, useCallback, useEffect } from "react";
import SegmentedControl from "../ui/segmentedControl";

const ProductList = () => {
  const desktop = useMediaQuery("(min-width:600px)");
  const [slice, setSlice] = useState(6);
  const [webSlice, setWebSlice] = useState(6);
  const [mobileSlice, setMobileSlice] = useState(6);
  const [filter, setfilter] = useState("all");

  const webProducts = products.filter((product) => product.type === "web");
  const mobileProducts = products.filter((product) => product.type === "app");

  const handleCallback = useCallback((val) => {
    setfilter(val);
  }, []);

  return (
    <div
      className="pl"
      id="products"
      style={{ paddingTop: desktop ? 70 : 300 }}
    >
      <div className="pl-texts">
        <h1 className="pl-title">Product List</h1>
        <p className="pl-desc">
          designed by Frezeh is a creative portfolio that your work has been
          waiting for. Beautiful websites, stunning portfolio styles & a whole
          lot more awaits inside.
        </p>
      </div>

      <SegmentedControl
        name="group-1"
        callback={(val) => handleCallback(val)}
        controlRef={useRef()}
        segments={[
          {
            label: "All",
            value: "all",
            ref: useRef(),
          },
          {
            label: "Web",
            value: "web",
            ref: useRef(),
          },
          {
            label: "Mobile",
            value: "app",
            ref: useRef(),
          },
        ]}
      />

      <div className="pl-list">
        {filter === "all" &&
          products
            .slice(0, slice)
            .map((item) => (
              <Product key={item.id} img={item.img} link={item.link} />
            ))}
        {filter === "web" &&
          webProducts
            .slice(0, webSlice)
            .map((item) => (
              <Product key={item.id} img={item.img} link={item.link} />
            ))}
        {filter === "app" &&
          mobileProducts
            .slice(0, mobileSlice)
            .map((item) => (
              <Product key={item.id} img={item.img} link={item.link} />
            ))}
      </div>
      <button
        onClick={() => {
          if (filter === "all") {
            setSlice((slice) => slice + 6);
          } else if (filter === "web") {
            setWebSlice((webSlice) => webSlice + 6);
          } else if (filter === "app") {
            setMobileSlice((mobileSlice) => mobileSlice + 6);
          }
        }}
        style={{
          display:
            filter === "all" && slice >= products.length
              ? "none"
              : filter === "web" && webSlice >= webProducts.length
              ? "none"
              : filter === "app" && mobileSlice >= mobileProducts.length
              ? "none"
              : "block",
        }}
      >
        Load More
      </button>
    </div>
  );
};

export default ProductList;
