import "./about.css";
import Developer from "../../img/software-developer.jpg";
// import Award from "../../img/phone.png";

const About = () => {
  return (
    <div className="a" id="about" style={{ paddingTop: 70 }}>
      <div className="a-left">
        <div className="a-card bg"></div>
        <div className="a-card">
          <img src={Developer} alt="" className="a-img" />
        </div>
      </div>
      <div className="a-right">
        <h1 className="a-title">About Me</h1>
        <p className="a-sub" style={{ fontSize: "22px" }}>
          Curious, communicative, resourceful and technology agnostic problem
          solver, capable of fusing creativity and strong industry knowledge to
          automate processes, systems and infrastructure. Web and Mobile Full
          Stack developer with years of experience across vast range of software
          development projects. My core competency includes JavaScript,
          Typescript and Go. I love building solutions for real world problems
          using React, Vue, NodeJs, Go and React Native.
        </p>
        {/* <div className="a-award">
          <img src={Award} alt="" className="a-award-img" />
          <div className="a-award-texts">
            <h4 className="a-award-title">International Design Awards 2021</h4>
            <p className="a-award-desc">
              Nemo enim ipsam voluptatem quia voluptas sit aspernatur autodit
              and fugit.
            </p>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default About;
