import "./intro.css";
import { init } from "ityped";
import { useEffect, useRef } from "react";
import Me from "../../img/me.png";
import Down from "../../img/down.png";

const Intro = () => {
  const textRef = useRef();

  useEffect(() => {
    init(textRef.current, {
      showCursor: true,
      backDelay: 1500,
      backSpeed: 60,
      strings: ["Web Developer", "Mobile Developer", "Software Engineer"],
    });
  }, []);

  return (
    <div className="i" style={{ paddingTop: 70 }}>
      <div className="i-left">
        <div className="i-left-wrapper">
          <div>
            <h2>Hi There, I'm</h2>
            <h1>Frank Ezeh</h1>
            <h3>
              Freelance{" "}
              <span
                ref={textRef}
                className="gradient-text"
                style={{ fontSize: "30px" }}
              ></span>
            </h3>
            <p className="i-desc" style={{ fontSize: "25px", marginTop: 20 }}>
              I design and develop services for customers of all sizes,
              specializing in creating stylish, modern websites, web services
              and mobile applications..
            </p>
          </div>
          <a href="#products">
            <div className="animate">
              {/* <img src={Down} alt="" /> */}
              <span className="image__icon">
                <svg
                  style={{ width: "60px", height: "60px", color: "white" }}
                  aria-hidden="true"
                  viewBox="0 0 60 60"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M20.999 26L29.999 35L38.999 26"
                    stroke="#BFBFBF"
                    strokeWidth="4"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </span>
            </div>
          </a>
        </div>
      </div>
      <div className="i-right">
        <div className="i-bg">
          <img src={Me} alt="" className="i-img" />
        </div>
      </div>
    </div>
  );
};

export default Intro;
